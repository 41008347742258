import React from 'react';
import { PageProps, graphql } from 'gatsby';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import { rhythm } from '../utils/typography';
import Header from '../components/Header';
import { Post, PageContext } from '../types';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';

deckDeckGoHighlightElement();

interface DataProps {
  markdownRemark: Post;
}

const AboutPage = ({ data }: PageProps<DataProps, PageContext>): JSX.Element => {
  const post = data.markdownRemark;
  return (
    <>
      <Header pageId={'about'} />
      <Layout>
        <article>
          <header>
            <h1
              style={{
                marginTop: rhythm(1),
                marginBottom: 0,
              }}
            >
              About
            </h1>
          </header>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
          <hr
            style={{
              marginBottom: rhythm(1),
            }}
          />
          <footer>
            <Bio />
          </footer>
        </article>
      </Layout>
    </>
  );
};

export default AboutPage;

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/content/about/index.md/" }) {
      html
    }
  }
`;
